<template>
  <div>
    <div v-if="checkPermission('dashboard statistic')">
      
      <b-card
        no-body
        class="card-statistics"
      >
        <b-card-header>
          <b-card-title>Statistic</b-card-title>
        </b-card-header>
        <b-card-body class="statistics-body">
          <b-row>
            <b-col
              v-for="item in userStatistic"
              :key="item.icon"
              xl="3"
              sm="6"
              :class="item.customClass"
              @click="item.route != null ? goTo(item.route) : ''"
              style="cursor: pointer;"
            >
              <b-media no-body>
                <b-media-aside

                  class="mr-2"
                >
                  <b-avatar
                    size="48"
                    :variant="item.color"
                  >
                    <feather-icon
                      size="24"
                      :icon="item.icon"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">
                    {{ data[item.key] }}
                  </h4>
                  <b-card-text class="font-small-3 mb-0">
                    {{ item.subtitle }}
                  </b-card-text>
                </b-media-body>
              </b-media>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>

      <div class="row">

        <div class="col-md-3">
          <div class="card">
            <div class="card-header d-flex justify-content-between">
                <h4 class="card-title">User Registration</h4>
            </div>
            <div class="card-body">
                <div class="d-flex justify-content-between mb-1">
                    <div class="d-flex align-items-center">
                        <feather-icon
                          size="21"
                          :icon="'CircleIcon'"
                          :class="'font-medium-1 text-info'"
                        />
                        <span class="font-weight-bold ml-75" @click="checkPermission('show users') ? goTo({ name: 'users.index'}) : ''" style="cursor: pointer;">
                          All User
                        </span>
                    </div>
                    <span>{{ data.user_count }}</span>
                </div>
                <div class="d-flex justify-content-between mb-1">
                    <div class="d-flex align-items-center">
                        <feather-icon
                          size="21"
                          :icon="'CircleIcon'"
                          :class="'font-medium-1 text-success'"
                        />
                        <span class="font-weight-bold ml-75" @click="checkPermission('show users') ? goTo({ name: 'users.index', query: {status: 'active'}}) : ''" style="cursor: pointer;">
                          Approved
                        </span>
                    </div>
                    <span>{{ data.user_active_count }}</span>
                </div>
                <div class="d-flex justify-content-between">
                    <div class="d-flex align-items-center">
                        <feather-icon
                          size="21"
                          :icon="'CircleIcon'"
                          :class="'font-medium-1 text-danger'"
                        />
                        <span class="font-weight-bold ml-75" @click="checkPermission('show users') ? goTo({ name: 'users.index', query: {status: 'pending'}}) : ''" style="cursor: pointer;">
                          Pending
                        </span>
                    </div>
                    <span>{{ data.user_pending_count }}</span>
                </div>
            </div>
          </div>
        </div>

        <div class="col-md-3">
          <div class="card">
            <div class="card-header d-flex justify-content-between">
                <h4 class="card-title">User Topup</h4>
            </div>
            <div class="card-body">
                <div class="d-flex justify-content-between mb-1">
                    <div class="d-flex align-items-center">
                        <feather-icon
                          size="21"
                          :icon="'CircleIcon'"
                          :class="'font-medium-1 text-info'"
                        />
                        <span class="font-weight-bold ml-75" @click="checkPermission('show topups') ? goTo({ name: 'topup.index'}) : ''" style="cursor: pointer;">
                          All Topup
                        </span>
                    </div>
                    <span>{{ data.cash_topup_count }}</span>
                </div>
                <div class="d-flex justify-content-between mb-1">
                    <div class="d-flex align-items-center">
                        <feather-icon
                          size="21"
                          :icon="'CircleIcon'"
                          :class="'font-medium-1 text-success'"
                        />
                        <span class="font-weight-bold ml-75" @click="checkPermission('show topups') ? goTo({ name: 'topup.index', query: {status: 'success'}}) : ''" style="cursor: pointer;">
                          Success
                        </span>
                    </div>
                    <span>{{ data.cash_topup_success_count }}</span>
                </div>
                <div class="d-flex justify-content-between">
                    <div class="d-flex align-items-center">
                        <feather-icon
                          size="21"
                          :icon="'CircleIcon'"
                          :class="'font-medium-1 text-danger'"
                        />
                        <span class="font-weight-bold ml-75" @click="checkPermission('show topups') ? goTo({ name: 'topup.index', query: {status: 'pending'}}) : ''" style="cursor: pointer;">
                          Pending
                        </span>
                    </div>
                    <span>{{ data.cash_topup_pending_count }}</span>
                </div>
            </div>
          </div>
        </div>

        <div class="col-md-3">
          <div class="card">
            <div class="card-header d-flex justify-content-between">
                <h4 class="card-title">User Cash Withdrawal</h4>
            </div>
            <div class="card-body">
                <div class="d-flex justify-content-between mb-1">
                    <div class="d-flex align-items-center">
                        <feather-icon
                          size="21"
                          :icon="'CircleIcon'"
                          :class="'font-medium-1 text-info'"
                        />
                        <span class="font-weight-bold ml-75" @click="checkPermission('show withdraws') ? goTo({ name: 'withdraw.index'}) : ''" style="cursor: pointer;">
                          All Withdraw
                        </span>
                    </div>
                    <span>{{ data.cash_withdraw_count }}</span>
                </div>
                <div class="d-flex justify-content-between mb-1">
                    <div class="d-flex align-items-center">
                        <feather-icon
                          size="21"
                          :icon="'CircleIcon'"
                          :class="'font-medium-1 text-success'"
                        />
                        <span class="font-weight-bold ml-75" @click="checkPermission('show withdraws') ? goTo({ name: 'withdraw.index', query: {status: 'success'}}) : ''" style="cursor: pointer;">
                          Success
                        </span>
                    </div>
                    <span>{{ data.cash_withdraw_success_count }}</span>
                </div>
                <div class="d-flex justify-content-between">
                    <div class="d-flex align-items-center">
                        <feather-icon
                          size="21"
                          :icon="'CircleIcon'"
                          :class="'font-medium-1 text-danger'"
                        />
                        <span class="font-weight-bold ml-75" @click="checkPermission('show withdraws') ? goTo({ name: 'withdraw.index', query: {status: 'pending'}}) : ''" style="cursor: pointer;">
                          Pending
                        </span>
                    </div>
                    <span>{{ data.cash_withdraw_pending_count }}</span>
                </div>
            </div>
          </div>
        </div>

        <div class="col-md-3">
          <div class="card">
            <div class="card-header d-flex justify-content-between">
                <h4 class="card-title">User Bank Account</h4>
            </div>
            <div class="card-body">
                <div class="d-flex justify-content-between mb-1">
                    <div class="d-flex align-items-center">
                        <feather-icon
                          size="21"
                          :icon="'CircleIcon'"
                          :class="'font-medium-1 text-info'"
                        />
                        <span class="font-weight-bold ml-75" @click="checkPermission('show user-bankaccount') ? goTo({ name: 'user-bank.index'}) : ''" style="cursor: pointer;">
                          All Bank Account
                        </span>
                    </div>
                    <span>{{ data.user_bankaccount_count }}</span>
                </div>
                <div class="d-flex justify-content-between mb-1">
                    <div class="d-flex align-items-center">
                        <feather-icon
                          size="21"
                          :icon="'CircleIcon'"
                          :class="'font-medium-1 text-success'"
                        />
                        <span class="font-weight-bold ml-75" @click="checkPermission('show user-bankaccount') ? goTo({ name: 'user-bank.index', query: {status: 'approved'}}) : ''" style="cursor: pointer;">
                          Approved
                        </span>
                    </div>
                    <span>{{ data.user_bankaccount_approved_count }}</span>
                </div>
                <div class="d-flex justify-content-between">
                    <div class="d-flex align-items-center">
                        <feather-icon
                          size="21"
                          :icon="'CircleIcon'"
                          :class="'font-medium-1 text-danger'"
                        />
                        <span class="font-weight-bold ml-75" @click="checkPermission('show user-bankaccount') ? goTo({ name: 'user-bank.index', query: {status: 'pending'}}) : ''" style="cursor: pointer;">
                          Pending
                        </span>
                    </div>
                    <span>{{ data.user_bankaccount_pending_count }}</span>
                </div>
            </div>
          </div>
        </div>

      </div>

      <b-card title="Warehouse Stock Volume">
        <div class="row">
          <div class="col-md-4 mb-1" v-for="item in data.warehouse_summary" :key="item.warehouse.slug">
            <h4>{{ item.warehouse.name }}</h4>
            <ul>
              <li v-for="stock in item.items" :key="stock.commodity.slug">
                {{ stock.commodity.code }}: <strong>{{ stock.volume }} {{ stock.commodity.unit }}</strong>
              </li>
            </ul> 
          </div>
        </div>
      </b-card>

      <b-card title="Commodity Price History">
        <commodity-price-history-chart></commodity-price-history-chart>
      </b-card>

      <b-card title="Expire Soon Bank Guarantee" v-if="checkPermission('transaction history cash user')">
        <cash-transaction-table :user_id="null" :expire_soon="true"></cash-transaction-table>
      </b-card>

      <b-card title="Current Auctions" v-if="checkPermission('show auctions')">
        <div class="table-responsive-sm">
        <table class="table b-table">
          <thead>
            <tr>
              <th width="25%">Description</th>
              <th>Price</th>
              <th>Date</th>
              <th>Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="auctions.data.length == 0">
              <td colspan="5" class="text-center">
                Auction is empty
              </td>
            </tr>
            <tr v-for="item in auctions.data" v-bind:key="item.uuid">
              <td>
                {{ item.commodity.name }}<br>
                <strong>{{ item.warehouse.name }}</strong>
              </td>
              <td>
                Open: <strong v-bind:class="item.price_change_percent > 0 ? 'text-success' : (item.price_change_percent == 0 ? 'text-default' : 'text-danger')">{{ item.sob_formatted }}</strong><br>
                Last: <strong v-bind:class="item.price_change_percent > 0 ? 'text-success' : (item.price_change_percent == 0 ? 'text-default' : 'text-danger')">{{ item.last_price_formatted }}</strong><br>
                Change: <strong v-bind:class="item.price_change_percent > 0 ? 'text-success' : (item.price_change_percent == 0 ? 'text-default' : 'text-danger')">{{ item.price_change_percent.toFixed(2) }}%</strong><br>
              </td>
              <td>
                {{ item.date }}
              </td>
              <td style="text-transform: capitalize;">{{ item.status }}</td>
            </tr>
            </tbody>
            </table>
          </div>
      </b-card>

    </div>
    <div v-else>
      <b-card title="Welcome 🚀">
        <b-card-text>Welcome to Dashboard.</b-card-text>
      </b-card>
    </div>
  </div>
</template>

<script>
import { BBadge, BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody, BSpinner } from 'bootstrap-vue'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'
import CommodityPriceHistoryChart from '@/views/components/CommodityPriceHistoryChart.vue'
import CashTransactionTable from '@/views/components/CashTransactionTable.vue'
import moment from 'moment'

export default {
  title () {
    return `Dashboard`
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BBadge,
    CommodityPriceHistoryChart,
    CashTransactionTable,
  },
  setup(props) {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    }
  },
  data() {
    return {
      data: Object,
      userStatistic: [
          {
            icon: 'KeyIcon',
            key: 'user_admin_count',
            color: 'light-success',
            subtitle: 'Admin User',
            customClass: 'mb-2 mb-xl-0',
            route: { name: 'admin.index'}
          },
          {
            icon: 'UsersIcon',
            key: 'user_count',
            color: 'light-primary',
            subtitle: 'User (Trader)',
            customClass: 'mb-2 mb-xl-0',
            route: { name: 'users.index'}
          },
          {
            icon: 'HomeIcon',
            key: 'warehouse_count',
            color: 'light-success',
            subtitle: 'Depository',
            customClass: 'mb-2 mb-xl-0',
            route: { name: 'warehouse.index'}
          },
          {
            icon: 'BoxIcon',
            key: 'commodity_count',
            color: 'light-success',
            subtitle: 'Commodity',
            customClass: 'mb-2 mb-xl-0',
            route: { name: 'commodities.index'}
          },
      ],
      auctions: Object,
    }
  },
  mounted() {
    if (checkPermission('dashboard statistic')) {
      this.getSummary();
      this.getAuctions();
    }
    this.reloadUserData();

    // var context = this
    // this.$echo.channel('DashboardAdminUpdate').listen('.DashboardAdminUpdate', (payload) => {
    //   context.getSummary();

    //   if (payload.type == 'auction') {
    //     context.getAuctions();  
    //   }
    // });
    
  },
  methods: {
    goTo(route) {
      this.$router.push(route)
    },
    getSummary() {
      this.$http.get('/admin/statistic', { useCache: true })
      .then(response => {
        this.data = response.data.data
      })
    },
    getAuctions() {
      var queryParams = {}
      queryParams.per_page = 50
      queryParams.start_at_daterange = moment().format('DD/MM/YYYY');
      queryParams.start_at_daterange += '-' + moment().add(1, 'years').format('DD/MM/YYYY');

      this.$http.get('/admin/auction', {
        params: queryParams,
        useCache: true
      })
      .then(response => {
        this.auctions = response.data.data
      })
    },
    reloadUserData() {
      this.$http.get('/me')
                .then(response => {
                  if (response.data.data.permissions.length > 0 || response.data.data.roles.length > 0) {
                    var userData = JSON.stringify(response.data.data);
                    localStorage.setItem('userData', userData)
                  }
                });
    }
  },
}
</script>